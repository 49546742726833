<template>
	<div>
		<ContentHeader3 title="Tindakan" url="/tindakan" title2="Data Tindakan" url2="/data-tindakan" subTitle="Tambah Data Tindakan" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
				<div class="col">
					<div class="card">
						<div class="card-header row">
							<h3 class="card-title">Input Data Tindakan</h3>
						</div>
						<div class="card-body row">
							<div class="col-lg-2"></div>
							<div class="col-lg-8">
								<form @submit.prevent="prosesTindakan">
									<div class="px-lg-2 ">
										<div class="form-group">
										<label for="kode_tindakan">Kode</label>
										<input type="text" class="form-control" id="kode_tindakan" name="kode_tindakan" disabled="" v-model="tindakan.kode_tindakan">
										</div>
										<div class="form-group">
										<label for="jenis_tindakan">Jenis <span class="text-danger">*</span></label>
										<select class="custom-select form-control" v-model="tindakan.jenis" @change="generateKodeTindakan()">
											<option selected hidden=""></option>
											<option v-for="(row, index) in jenisTindakan" :key="index" :value="row">{{ row.name }}</option>
										</select>
										</div>
										<!-- tambah cabang -->
										<div class="w-100 mb-3"  v-if="toogleCabang">
										<label for="cabang">Pilih Cabang<span class="text-danger" >*</span></label>
										<el-select id="cabang_id" v-model="tindakan.cabang_id" filterable placeholder="Pilih Cabang" class="w-100" :loading="loading_cabang">
											<el-option v-for="(row, index) in cabang" :key="index" :label="row.nama" :value="row.id" />
										</el-select>
										</div>
										<!-- tambah cabang -->
										<div class="form-group">
										<label for="kategori">Type <span class="text-danger">*</span></label>
										<select class="custom-select form-control" v-model="tindakan.poli">
											<option selected hidden=""></option>
											<option v-for="(row, index) in poli" :value="row" :key="index">{{ row.nama }}</option>
										</select>
										</div>
										<div class="form-group">
										<label for="kategori">Kategori <span class="text-danger">*</span></label>
										<select class="custom-select form-control" v-model="tindakan.kategori">
											<option selected hidden=""></option>
											<option value="tindakan">Tindakan</option>
											<option value="paket">Paket</option>
											<option value="jasa">Jasa</option>
										</select>
										</div>
										<div class="form-group">
										<label for="nama">Nama <span class="text-danger">*</span></label>
										<input type="text" class="form-control" id="nama" required="" name="nama" autocomplete="off" v-model="tindakan.nama">
										</div>
										<div class="form-group">
										<label for="lama_tindakan">Durasi <span class="text-danger">*</span></label>
										<div class="input-group mb-3">
											<div class="input-group-prepend">
											<div type="button" class="btn btn-secondary">Menit</div>
											</div>
											<!-- /btn-group -->
											<input type="number" class="form-control" required="" id="lama_tindakan" name="lama_tindakan"  v-model="tindakan.lama_tindakan">
										</div>
										</div>
										<div class="form-group">
										<label for="lama_tindakan">Tarif <span class="text-danger">*</span></label>
										<div class="row">
											<div class="col">
												<span>Umum</span>
												<div class="input-group mb-3">
													<div class="input-group-prepend">
													<div type="button" class="btn btn-secondary">Rp.</div>
													</div>
													<!-- /btn-group -->
													<input class="form-control" type="number" required="" name="harga_pasien_baru" v-model="tindakan.harga_pasien_baru">
												</div>
											</div>
											<div class="col">
												<span>Member</span>
												<div class="input-group mb-3">
													<div class="input-group-prepend">
													<div type="button" class="btn btn-secondary">Rp.</div>
													</div>
													<!-- /btn-group -->
													<input class="form-control" type="number" required="" name="harga_pasien_member" v-model="tindakan.harga_pasien_member">
												</div>
											</div>
										</div>
										</div>
										<div class="form-group">
											<div class="d-flex justify-content-between align-items-center">
												<label>Tarif Per Layanan</label>
												<p class="btn btn-light" @click="tambahHargaLayanan()">Tambah</p>
											</div>
											<table class="table table-bordered table-hover mt-2">
												<thead>
													<tr>
														<th scope="col" class="text-sm">Layanan</th>
														<th scope="col" class="text-sm">Harga</th>
														<th scope="col" class="text-sm text-center">Aksi</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(row, index) in tindakan.harga_per_layanan" :key="index">
														<td>
															<el-select v-model="row.layanan_id" placeholder="Pilih Layanan" :loading="loading_layanan" filterable clearable class="w-100">
																<el-option v-for="(row, index) in dataLayanan" :key="index" :value="row.id" :label="row.nama"></el-option>
															</el-select>
														</td>
														<td>
															<input class="form-control" type="number" v-model="row.harga">
														</td>
														<td class="d-flex justify-content-center">
															<p class="mx-auto btn btn-outline-danger" @click="hapusHargaLayanan(index)">Hapus</p>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div class="form-group">
										<label for="modal">Modal <span class="text-danger">*</span></label>
										<div class="input-group mb-3">
											<div class="input-group-prepend">
											<div type="button" class="btn btn-secondary">Rp.</div>
											</div>
											<!-- /btn-group -->
											<input type="number" class="form-control" id="modal" required="" name="modal"  v-model="tindakan.modal">
										</div>
										</div>
										<div class="form-group">
											<label>Fee </label>
											<div class="row">
												<div class="col">
													<span for="fee_dokter">Fee Dokter <span class="text-danger">*</span></span>
													<div class="input-group mb-3">
														<div class="input-group-prepend">
														<div class="btn btn-secondary">{{ fee_dokter }}</div>
														</div>
														<!-- /btn-group -->
														<input type="number" class="form-control" id="fee_dokter" step="any" required="" name="fee_dokter" v-model="tindakan.fee_dokter" @keyup="feeForDokter()">
													</div>
												</div>
												<div class="col">
													<span for="fee_dokter">Fee Beautician <span class="text-danger">*</span></span>
													<div class="input-group mb-3">
														<div class="input-group-prepend">
														<div class="btn btn-secondary">{{ fee_beautician }}</div>
														</div>
														<!-- /btn-group -->
														<input type="number" class="form-control" id="fee_beautician" step="any" required="" name="fee_dokter" v-model="tindakan.fee_beautician" @keyup="feeForBeautician()">
													</div>
												</div>
												<div class="col">
													<span for="fee_dokter">Fee Sales <span class="text-danger">*</span></span>
													<div class="input-group mb-3">
														<div class="input-group-prepend">
														<div class="btn btn-secondary">{{ fee_sales }}</div>
														</div>
														<!-- /btn-group -->
														<input type="number" class="form-control" id="fee_sales" step="any" required="" name="fee_dokter" v-model="tindakan.fee_sales" @keyup="feeForSales()">
													</div>
												</div>
											</div>
										</div>
										<div class="form-group">
										<label for="point">Point</label>
										<input type="number" class="form-control" id="point" min="0" name="point"  v-model="tindakan.point">
										</div>
										<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
											<div>Submit</div>	
											<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
												<span class="sr-only">Loading...</span>
											</div>
										</button>
										</div>
									<!-- /.card-body -->					               
									</form>	
							</div>
							<div class="col-lg-2"></div>
						</div>
					</div>
				</div>
			</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader3 from '@/components/ContentHeader3'

import store from '@/store'
import router from '@/router'
import { reactive, computed, onMounted, ref } from 'vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import tambah_nol from '@/tambah_nol.js'

// Data
const user 			= computed(() => store.getters['auth/user'])
const jenisTindakan = computed(() => store.getters['tindakan/getJenisTindakan'])
const poli			= computed(() => store.getters['poli/getPoli'])

// fetch data
store.dispatch('poli/fetchPoli')

// Data
const tindakan = reactive({
	cabang_id			: '',
	poli				: null,
	kode_tindakan		: '',
	kode_jenis_tindakan	: '',
	jenis_tindakan		: '',
	kategori			: '',
	nama				: '',
	lama_tindakan		: '',
	harga_pasien_baru	: '',
	harga_pasien_member	: '',
	modal				: '',
	fee_dokter			: '',
	fee_beautician		: '',
	fee_sales			: '',
	point				: 0,
	harga_per_layanan	: [],
	harga_per_layanan_convertion: null
})

const { generateKodeTindakan, getKodeCabang } = useKodeTindakan(tindakan, user)

const { prosesTindakan, loading } = useProsesTindakan(tindakan)

const { 
	fee_dokter,
	fee_beautician,
	fee_sales,
	feeForDokter,
	feeForBeautician,
	feeForSales
} = useInputPersen(tindakan)

//cabang
const toogleCabang = ref(false)
// const cabang_select_value = ref('')
const cabang = ref([])
const loading_cabang = ref(true)
const getCabang = async () => {
    let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

    if (response.data.jenis == 'pusat') {
        toogleCabang.value = true
        let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
    
        if (data != 'kosong') {
            cabang.value = data
            loading_cabang.value = false
        } else {
            cabang.value = []
            loading_cabang.value = false
        }
    }
}
//cabang

	// LAYANAN
	const dataLayanan = ref([])
	const loading_layanan = ref(true)
	const cek_data_layanan = ref(false)

	const getLayanan = async () => {
		await axios.get(`api/layanan/getWhereAktif`)
		.then((response) => {
			if (response.data == 'kosong') {
				loading_layanan.value = false
				cek_data_layanan.value = true
				dataLayanan.value = []
			} else {
				loading_layanan.value = false
				cek_data_layanan.value = false
				dataLayanan.value = response.data
			}
		})

	}
	// END LAYANAN

const tambahHargaLayanan = () => {
	tindakan.harga_per_layanan.push({ layanan_id: null, harga: 0 })
}

const hapusHargaLayanan = (index) => {
	tindakan.harga_per_layanan.splice(index, 1)
}

onMounted(() => {
	getKodeCabang()
	getLayanan()
	getCabang()
})


function useKodeTindakan (tindakan, user) {
	// Generate Kode Tindakan
	const kode_cabang = ref()
	const nomor_urut = ref('')
	const kode = ref('')

	const getKodeCabang = async () => {
		await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)
    	.then((response) => {
    		kode_cabang.value = `${response.data.kode_cabang}`
    	}) 
    	.catch((error) => {
    		console.log(error)
    	})
	}

    const generateKodeTindakan = async () => {
    	kode.value = `${tindakan.jenis.value}${kode_cabang.value}`
    	await axios.get(`api/tindakan/getForGenerateCode/${kode.value}`)
    	.then((response) => {
    		if(response.data == 'kosong'){
    			nomor_urut.value = tambah_nol(1)
    			isi_jenis_tindakan()
    		} else {
    			let str = response.data
				let length = str.length
				let nlength = length - 3
				let id = str.slice(nlength, length) 
    			id = Math.abs(id)
    			nomor_urut.value = tambah_nol(id + 1)
    			isi_jenis_tindakan()
    		}
    	})
    	.catch((error) => {
    		console.log(error)
    	})

    }

    const generateKode = () => {
		tindakan.kode_tindakan = `${tindakan.kode_jenis_tindakan}${kode_cabang.value}${nomor_urut.value}`
	}

	const isi_jenis_tindakan = () => {
		if(tindakan.jenis){
			tindakan.kode_jenis_tindakan = tindakan.jenis.value
			tindakan.jenis_tindakan = tindakan.jenis.name
		}
		generateKode()
	}

    // End

    return {
    	generateKodeTindakan,
    	getKodeCabang
    }
}

function useProsesTindakan (tindakan) {
	const loading = ref(false)

	const resetDataTindakan = () => {
		tindakan.kode_tindakan = '',
		tindakan.jenis_tindakan = '',
		tindakan.nama = '',
		tindakan.lama_tindakan = '',
		tindakan.harga_pasien_baru = '',
		tindakan.harga_pasien_member = '',
		tindakan.modal = '',
		tindakan.fee_dokter = '',
		tindakan.point = 0
	}

	const prosesTindakan = () => {
		loading.value = true
		tindakan.harga_per_layanan_convertion = tindakan.harga_per_layanan ? JSON.stringify(tindakan.harga_per_layanan) : null

		axios.post('api/tindakan/create', tindakan)
		.then((response) => {
			Swal.fire({
			  title: 'Berhasil!',
			  text: 'Berhasil Menanbahkan Data',
			  icon: 'success',
			  showConfirmButton: false,
			  timer: 1500
			})	
			loading.value = false
			console.log(response)
			resetDataTindakan()
			router.replace('/data-tindakan')
		})
		.catch((error) => {
			Swal.fire({
			  title: 'Gagal!',
			  text: 'Gagal Menanbahkan Data',
			  icon: 'error',
			  confirmButtonText: 'Ok'
			})	
			loading.value = false
			console.log(error)
		})

    }

    return {
    	resetDataTindakan,
    	prosesTindakan,
    	loading
    }
}

function useInputPersen (tindakan) {
	const fee_dokter = ref('...')
	const fee_beautician = ref('...')
	const fee_sales = ref('...')

	const feeForDokter = () => {
		if (tindakan.fee_dokter <= 100) {
			fee_dokter.value = 'Persen (%)'
		} else {
			fee_dokter.value = 'Rp.'
		}
	}

	const feeForBeautician = () => {
		if (tindakan.fee_beautician <= 100) {
			fee_beautician.value = 'Persen (%)'
		} else {
			fee_beautician.value = 'Rp.'
		}
	}

	const feeForSales = () => {
		if (tindakan.fee_sales <= 100) {
			fee_sales.value = 'Persen (%)'
		} else {
			fee_sales.value = 'Rp.'
		}
	}

	return {
		fee_dokter,
		fee_beautician,
		fee_sales,
		feeForDokter,
		feeForBeautician,
		feeForSales,
	}
}

</script>


<style>
	
</style>